import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { map } from 'rxjs';
import { getAppRoute } from '../../shared/utils/getAppRoute';
import { AppRoutes } from '../../routes/constants/appRoutes';
import { AuthService } from '../services/auth.service';

export const authGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  const requiresAuth = route.data['requiresAuth'] === true;

  return authService.isAuthenticated$.pipe(
    map((isAuthenticated) => {
      if (requiresAuth && !isAuthenticated) {
        router.navigate([getAppRoute(AppRoutes.LANDING_PAGE)]);
        return false;
      }
      return true;
    })
  );
};
