import { environment } from '@environments/environment.development';

const { aladdinOnDemandUrl, googleMap } = environment;

const externalApiRoutes = {
  GMAP_JS_API: `https://maps.googleapis.com/maps/api/js?v=weekly&libraries=places&key=${googleMap.googleApiKey}`,
  GMAP_GET_DIRECTION_API: 'https://www.google.com/maps/dir/?api=1&destination=',
  GMAP_GEOCODE_API: `https://maps.googleapis.com/maps/api/geocode/json?key=${googleMap.googleApiKey}&latlng=`
};

export const apiRoutes = {
  ...externalApiRoutes,
  AUTHORIZATION_BEARER_TOKEN_API: `${aladdinOnDemandUrl}/authorizationserver/oauth/token`,
  REGISTER_USER_URL: `${aladdinOnDemandUrl}/services/v2/aladdin/users`,
  FORGOT_PASSWORD_URL: `${aladdinOnDemandUrl}/services/v2/aladdin/forgottenpasswordtokens`,
  RESET_PASSWORD_URL: `${aladdinOnDemandUrl}/services/v2/aladdin/resetpassword`,
  VALIDATE_OTP_URL: `${aladdinOnDemandUrl}/services/v2/aladdin/validateotp`,
  USER_PROFILE_URL: `${aladdinOnDemandUrl}/services/v2/aladdin/users`,
  PLP: environment.plpApiBaseUrl,
  PDP: environment.pdpApiBaseUrl,
  AVAILABILITY_MODAL: environment.pdpAvailabilityModalUrl,
  FIND_A_RETAILER: environment.findRetailerBaseUrl
};
