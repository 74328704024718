import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ICartItem, ICartState } from './cart.reducer';

// feature selector
export const selectCartState = createFeatureSelector<ICartState>('cart');

// select all cart items
export const selectCartItems = createSelector(selectCartState, (state: ICartState) => state.cartItems);

// select cart item by color number
export const selectCartItemByColorName = (colorNumber: number) =>
  createSelector(selectCartItems, (items: ICartItem[]) => items.find((item) => item.colorNumber === colorNumber));

// select total quantity of a specific item
export const selectItemQuantity = (colorNumber: number) =>
  createSelector(selectCartItems, (items: ICartItem[]) => {
    const item = items.find((item) => item.colorNumber === colorNumber);
    return item ? item.quantity : 0;
  });

// select whether a specific product is in cart
export const selectIsProductInCart = (colorNumber: number) =>
  createSelector(selectCartItems, (items: ICartItem[]) => items.some((item) => item.colorNumber === colorNumber));
