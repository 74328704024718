import { createReducer, on } from '@ngrx/store';
import { addProductToCatalog, removeProductFromCatalog, clearAllProductFromCatalog } from './catalogList.actions';
import { ProductDetailsResponse } from '@app/core/models/product/productDetailsResponse.model';

export interface IProductState {
  catalogList: ProductDetailsResponse[][];
}

export const initialState: IProductState = {
  catalogList: []
};

export const catalogReducer = createReducer(
  initialState,
  on(addProductToCatalog, (state, { product }) => ({
    ...state,
    catalogList: [...state.catalogList, product]
  })),
  on(removeProductFromCatalog, (state, { productId }) => ({
    ...state,
    catalogList: state.catalogList.filter(
      (subArray) => Array.isArray(subArray) && !subArray.every((product) => product.styleNumber === productId)
    )
  })),
  on(clearAllProductFromCatalog, () => initialState)
);
