export const emailPattern: RegExp =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const phonePattern: RegExp = /^[0-9]*$/;

export const styleWarrantyPattern: RegExp = /^styleWarranty\d+$/;

export const profileRouteRegex = /\/my-account\?menu=(overview|profile|order-history)/;

export const coordinatesPattern: RegExp = /^coordinates\d+$/;

export const zipCodePattern: RegExp = /^\d{5}$/;

export const numberPattern: RegExp = /[^0-9]/g;

export const alphaNumericPattern: RegExp = /[^\w\s]/gi;
