import { ApplicationConfig, isDevMode } from '@angular/core';
import { provideRouter, withComponentInputBinding, withHashLocation } from '@angular/router';
import { routes } from '@routes/app.routes';
import { provideClientHydration, withNoHttpTransferCache } from '@angular/platform-browser';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { TokenInterceptor } from '@interceptors/token.interceptor';
import { HttpRequestInterceptor } from '@interceptors/httpRequest.interceptor';
import { TransferStateInterceptor } from '@interceptors/transferState.interceptor';
import { productReducer } from '@store/compare-product/cpp.reducer';
import { catalogReducer } from '@store/catalogProduct/catalogList.reducer';
import 'reflect-metadata';
import { catalogSectionReducer } from '@store/sectionProduct/sectionList.reducer';
import { metaReducers, getInitialState } from '@store/metaReducer/storage.metaReducer';
import { cartReducer } from '@store/cart/cart.reducer';
import { findRetailerStoreReducer } from '@store/find-retailer/find-retailer.reducer';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, ...[withComponentInputBinding()]),
    provideClientHydration(withNoHttpTransferCache()),
    provideHttpClient(withFetch(), withInterceptorsFromDi()),
    provideStore(
      {
        compareProductList: productReducer,
        catalogList: catalogReducer,
        catalogSection: catalogSectionReducer,
        cart: cartReducer,
        findRetailerStore: findRetailerStoreReducer
      },
      {
        metaReducers,
        initialState: getInitialState()
      }
    ),
    provideStoreDevtools({ maxAge: 25, logOnly: !isDevMode() }),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TransferStateInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true
    }
  ]
};
