import { environment } from '@environments/environment';

const developmentAppRoutes = {
  DEVELOPMENT: 'dev',
  PREVIEW: 'preview',
  FEATURE_PREVIEW: 'feature-preview',
  LANDING_PAGE: 'landing-page',
  PLP: 'plp',
  PDP: 'pdp'
};

export const AppRoutes = {
  ...(environment.production ? {} : developmentAppRoutes),
  ROOT: '/',
  EMPTY: '',
  WILD_CARD: '**',
  PAGE_TYPE: ':pageType',
  MY_ACCOUNT: 'my-account',
  SEARCH: 'search',
  COMPARE_PRODUCT_PAGE: 'compare-products',
  CATALOG_CREATOR_PAGE: 'catalog-creator',
  MY_CART: 'my-cart',
  ORDER_CHECKOUT: 'order-checkout',
  ORDER_CONFIRMATION: 'order-confirmation',
  FIND_RETAILER: 'find-a-retailer'
};
