import { DealersModel } from '@models/retailer/dealers.model';
import { createReducer, on } from '@ngrx/store';
import { makeMyRetailer } from './find-retailer.action';

export interface IFindRetailerStore {
  myRetailer?: DealersModel;
}

export const initialState: IFindRetailerStore = {
  myRetailer: new DealersModel()
};

export const findRetailerStoreReducer = createReducer(
  initialState,
  on(makeMyRetailer, (state, { myRetailer }) => ({
    ...state,
    myRetailer
  }))
);
