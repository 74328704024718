import { ActionReducer, Action, MetaReducer } from '@ngrx/store';
import { IProductStyleState } from '@store/compare-product/cpp.reducer';
import { IProductState as IProductCatlog } from '@store/catalogProduct/catalogList.reducer';
import { ICatalogSectionState } from '@store/sectionProduct/sectionList.reducer';
import { StoreConstants } from '@constants/storeConstants';
import { ICartState } from '@store/cart/cart.reducer';
import { IFindRetailerStore } from '@store/find-retailer/find-retailer.reducer';

interface IState {
  compareProductList: IProductStyleState;
  catalogList: IProductCatlog;
  catalogSection: ICatalogSectionState;
  cart: ICartState;
  findRetailerStore: IFindRetailerStore;
}

const { STORAGE_KEY, INITIALIZATION_PATH } = StoreConstants;

// Utility function to check if localStorage is available
const isLocalStorageAvailable = (): boolean => {
  try {
    return typeof localStorage !== 'undefined';
  } catch {
    return false;
  }
};

//Function to serialize the state
const serializeState = (state: IState): string => {
  return JSON.stringify({
    compareProductList: state.compareProductList,
    catalogList: state.catalogList,
    catalogSection: state.catalogSection,
    cart: state.cart,
    findRetailerStore: state.findRetailerStore
  });
};

// Meta reducer to save specific parts of the state to local storage after each action
export const storageMetaReducer = (reducer: ActionReducer<IState>): ActionReducer<IState> => {
  const storageKey = STORAGE_KEY;

  return (state: IState | undefined, action: Action) => {
    const nextState = reducer(state, action);

    // Avoid saving state on initial load and check for localStorage availability
    if (action.type !== INITIALIZATION_PATH && isLocalStorageAvailable()) {
      const serializedState = serializeState(nextState);
      localStorage.setItem(storageKey, serializedState);
    }

    return nextState;
  };
};

// Function to load the saved initial state from local storage
export const getInitialState = (): Partial<IState> | undefined => {
  const storageKey = STORAGE_KEY;
  if (isLocalStorageAvailable()) {
    const savedState = localStorage.getItem(storageKey);
    return savedState ? JSON.parse(savedState) : undefined;
  }
  return undefined;
};

// Adding storageMetaReducer to the list of meta reducers for NgRx store configuration
export const metaReducers: MetaReducer<IState>[] = [storageMetaReducer];
