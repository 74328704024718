import { ICatalogSection } from '@app/core/interfaces/catalogCreatorSection.interface';
import { createReducer, on } from '@ngrx/store';
import * as CatalogSectionActions from './sectionList.action';

export interface ICatalogSectionState {
  sections: ICatalogSection[];
}

export const initialState: ICatalogSectionState = {
  sections: []
};

export const catalogSectionReducer = createReducer(
  initialState,
  on(CatalogSectionActions.addSection, (state, { section }) => ({
    ...state,
    sections: [...state.sections, section]
  })),

  on(CatalogSectionActions.updateSection, (state, { section }) => ({
    ...state,
    sections: state.sections.map((s) => (s.id === section.id ? { ...s, ...section } : s))
  })),

  on(CatalogSectionActions.removeSection, (state, { sectionId }) => ({
    ...state,
    sections: state.sections.filter((section) => section.id !== sectionId)
  })),
  on(CatalogSectionActions.removeProductFromSection, (state, { sectionId, productId }) => ({
    ...state,
    sections: state.sections.map((section) =>
      section.id === sectionId
        ? {
            ...section,
            products: section.products.filter((product) => product[0].styleNumber !== productId),
            productsCount: section.products.filter((product) => product[0].styleNumber !== productId).length
          }
        : section
    )
  })),

  on(CatalogSectionActions.clearAllSection, (state) => ({
    ...state,
    sections: []
  }))
);
