import 'reflect-metadata';
import { apiRoutes } from '@routes/constants/apiRoutes';
import { Expose, Type } from 'class-transformer';

export interface IHours {
  open?: string;
  close?: string;
}

export class WeeklyHoursModel {
  dayOfWeek?: string;

  hours?: IHours;

  @Expose({ name: 'OpenDay' })
  openDay?: string;

  @Expose({ name: 'CloseDay' })
  closeDay?: string;
}

export class OperatingHoursModel {
  dayOfWeek?: string;

  hours?: IHours;

  @Expose({ name: 'OpenDay' })
  openDay?: string;

  @Expose({ name: 'CloseDay' })
  closeDay?: string;
}

export class OfferModel {
  @Expose({ name: 'offerID' })
  offerId?: string;

  offerName?: string;

  storeType?: string;
}

export class DealersModel {
  dealerID?: number;

  dealerNumber?: string;

  @Expose({ name: 'shipto' })
  shipTo?: string;

  storeName?: string;

  address1?: string;

  address2?: string;

  address3?: string;

  city?: string;

  state?: string;

  zip?: string;

  phone?: string;

  affiliation?: string;

  fiveStar?: string;

  platinum?: string;

  saleParticipating?: string;

  karastanSaleParticipating?: string;

  saleIsActive?: string;

  latitude?: number;

  longitude?: number;

  dealerUrl?: string;

  storeUrl?: string;

  dealerEmail?: string;

  distance?: number;

  clickToCallPhone?: string;

  premier?: string;

  edgeSelect?: boolean;

  edgePremier?: boolean;

  reviewCount?: number;

  mohawkConsumerFinance?: boolean;

  karastanConsumerFinance?: boolean;

  edgeOmnify?: boolean;

  @Expose({ name: 'TotalDealerCount' })
  totalDealerCount?: number;

  @Expose({ name: 'AuthorizedFlag' })
  authorizedFlag?: boolean;

  dealerOfferings?: string[];

  @Type(() => OperatingHoursModel)
  operatingHours?: OperatingHoursModel[];

  @Type(() => OfferModel)
  offers?: OfferModel[];

  @Type(() => WeeklyHoursModel)
  weeklyHours?: WeeklyHoursModel[];

  // need to update the type after confirmation
  description?: string;

  logoUrl?: string;

  facebookUrl?: string;

  storeWebsiteUrl?: string;

  twitterUrl?: string;

  flickrUrl?: string;

  youtubeUrl?: string;

  blogUrl?: string;

  pinterestUrl?: string;

  instagramUrl?: string;

  houzzUrl?: string;

  googlePlusUrl?: string;

  metaDescription?: string;

  metaKeywords?: string;

  pageTitle?: string;

  reviews?: string;

  reviewSiteUri?: string;

  affiliationCode?: string;

  @Expose({ name: 'birdeyebusinessid' })
  birdEyeBusinessId?: string;

  averageRating?: string;

  sapAccountNumber?: string;

  dealerType?: string;

  get fullAddress() {
    return [this.address1, this.address2, this.address3, this.city, this.state, this.zip].filter(Boolean).join(', ');
  }

  get position(): google.maps.LatLngLiteral {
    return { lat: this.latitude ?? 0, lng: this.longitude ?? 0 };
  }

  get direction() {
    return `${apiRoutes.GMAP_GET_DIRECTION_API}${this.latitude ?? ''},${this.longitude ?? ''}`;
  }
}
