import { ProductDetailsResponse } from '@app/core/models/product/productDetailsResponse.model';
import { createAction, props } from '@ngrx/store';

export const addProductToCatalog = createAction(
  '[Catalog] Add Product',
  props<{ product: ProductDetailsResponse[] }>()
);

export const removeProductFromCatalog = createAction('[Catalog] Remove Product', props<{ productId: string }>());

export const clearAllProductFromCatalog = createAction('[Catalog] Clear All');
