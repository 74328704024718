import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { AppRoutes } from '@app/routes/constants/appRoutes';
import { getAppRoute } from '@app/shared/utils/getAppRoute';
import { BehaviorSubject } from 'rxjs';
import { LocalStorage } from '../enum/localStorage.enum';
import { profileRouteRegex } from '@app/shared/constants/regexConfigConstant';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private isAuthenticatedSubject = new BehaviorSubject<boolean>(this.checkInitialAuthStatus());
  private authDrawerSubject = new BehaviorSubject<boolean>(false);

  public isAuthenticated$ = this.isAuthenticatedSubject.asObservable();
  public authDrawer$ = this.authDrawerSubject.asObservable();

  constructor(
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: object
  ) {
    this.setupAuthListener();
  }

  private checkInitialAuthStatus(): boolean {
    const accessToken = isPlatformBrowser(this.platformId)
      ? localStorage.getItem(LocalStorage.COMMERCIAL_ACCESS_TOKEN)
      : '';
    return !!accessToken;
  }

  private setupAuthListener() {
    if (isPlatformBrowser(this.platformId) && window) {
      window.addEventListener('storage', (event) => {
        if (event.key === LocalStorage.ACCESS_TOKEN) {
          const isAuthenticated = !!event.newValue;
          this.isAuthenticatedSubject.next(isAuthenticated);
        }
      });
    }
  }
  public updateAuthStatus(isAuthenticated: boolean) {
    this.isAuthenticatedSubject.next(isAuthenticated);

    if (isAuthenticated) {
      this.closeAuthDrawer();
    } else {
      this.clearLocalStorage();
    }
  }

  private clearLocalStorage() {
    if (!isPlatformBrowser(this.platformId)) return;

    Object.values(LocalStorage)
      .filter((value) => !value.includes('app-storage-state'))
      .forEach((value) => localStorage.removeItem(value));
  }
  public logout() {
    this.updateAuthStatus(false);

    const currentUrl = this.router.url;
    const accountRoutes = [
      AppRoutes.MY_ACCOUNT,
      AppRoutes.MY_CART,
      AppRoutes.ORDER_CHECKOUT,
      AppRoutes.ORDER_CONFIRMATION
    ];

    if (profileRouteRegex.test(currentUrl) || accountRoutes.some((route) => currentUrl.includes(route))) {
      this.router.navigate([getAppRoute(AppRoutes.LANDING_PAGE)]);
    }
  }

  public openAuthDrawer() {
    this.authDrawerSubject.next(true);
  }

  public closeAuthDrawer() {
    this.authDrawerSubject.next(false);
  }
}
