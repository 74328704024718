import { Routes } from '@angular/router';
import { AppRoutes } from '@routes/constants/appRoutes';
import { environment } from '@environments/environment';
import { authGuard } from '@app/core/guards/auth.guard';

const developmentRoutes = [
  {
    path: AppRoutes.DEVELOPMENT,
    loadComponent: () =>
      import('@layout/header-footer-layout/header-footer-layout.component').then((m) => m.HeaderFooterLayoutComponent),
    children: [
      {
        path: AppRoutes.PREVIEW,
        loadComponent: () => import('@views/preview/preview.component').then((m) => m.PreviewComponent)
      },
      {
        path: AppRoutes.FEATURE_PREVIEW,
        loadComponent: () =>
          import('@views/feature-preview/feature-preview.component').then((m) => m.FeaturePreviewComponent)
      },
      {
        path: AppRoutes.LANDING_PAGE,
        loadComponent: () => import('@views/landing-page/landing-page.component').then((m) => m.LandingPageComponent)
      },
      {
        path: AppRoutes.SEARCH,
        loadComponent: () => import('@views/search-page/search-page.component').then((m) => m.SearchPageComponent)
      },
      {
        path: AppRoutes.PLP,
        loadComponent: () =>
          import('@views/product-listing-page/product-listing-page.component').then(
            (m) => m.ProductListingPageComponent
          )
      },
      {
        path: AppRoutes.PDP,
        children: [
          {
            path: AppRoutes.WILD_CARD,
            loadComponent: () =>
              import('@views/product-detail-page/product-detail-page.component').then(
                (m) => m.ProductDetailPageComponent
              )
          }
        ]
      },
      {
        path: AppRoutes.MY_ACCOUNT,
        canActivate: [authGuard],
        data: { requiresAuth: true },
        loadComponent: () =>
          import('@features/profile-page/profile-page/profile-page.component').then((m) => m.ProfilePageComponent)
      },
      {
        path: AppRoutes.COMPARE_PRODUCT_PAGE,
        loadComponent: () =>
          import('@features/compare-product-table/compare-product-table.component').then(
            (m) => m.CompareProductTableComponent
          )
      },
      {
        path: AppRoutes.CATALOG_CREATOR_PAGE,
        loadComponent: () => import('@features/catalog-page/catalog-page.component').then((m) => m.CatalogPageComponent)
      },
      {
        path: AppRoutes.MY_CART,
        canActivate: [authGuard],
        data: { requiresAuth: true },
        loadComponent: () => import('@views/my-cart-page/my-cart-page.component').then((m) => m.MyCartPageComponent)
      },
      {
        path: AppRoutes.ORDER_CHECKOUT,
        canActivate: [authGuard],
        data: { requiresAuth: true },
        loadComponent: () =>
          import('@features/order-checkout/order-checkout/order-checkout.component').then(
            (m) => m.OrderCheckoutComponent
          )
      },
      {
        path: AppRoutes.ORDER_CONFIRMATION,
        canActivate: [authGuard],
        data: { requiresAuth: true },
        loadComponent: () =>
          import('@app/core/views/order-confirmation-page/order-confirmation-page').then(
            (m) => m.OrderConfirmationPageComponent
          )
      },
      {
        path: AppRoutes.FIND_RETAILER,
        loadComponent: () =>
          import('@features/find-retailer/find-retailer/find-retailer.component').then((m) => m.FindRetailerComponent)
      }
    ]
  }
];

export const routes: Routes = [
  ...(environment.production ? [] : developmentRoutes),
  {
    path: AppRoutes.WILD_CARD,
    loadComponent: () => import('@views/base/base.component').then((m) => m.BaseComponent)
  }
];
