import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  NotificationClass,
  NotificationIcon,
  NotificationSidebarClass,
  NotificationTypes
} from '@app/core/enum/notification.enum';
import { INotification, NotificationService } from '@app/core/services/notification.service';
import { SafeUrlPipe } from '@app/shared/pipes/safe-url.pipe';
import { NotificationConstants } from '@app/shared/constants/notificationConstants';
import { ICartItem } from '@app/store/cart/cart.reducer';
import { Observable, map } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectCartItems } from '@app/store/cart/cart.selector';

@Component({
  selector: 'aladdin-notification',
  standalone: true,
  imports: [CommonModule, SafeUrlPipe],
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent {
  @Input() isCartNotification: boolean = false;
  public cartItems$: Observable<ICartItem[]>;
  public totalSamplesCount: number = 0;

  public notifications: INotification[] = [];

  private closingNotifications: Set<number> = new Set();

  private notificationMapping: Partial<
    Record<
      NotificationTypes,
      {
        bgClass: NotificationClass;
        sidebarClass: NotificationSidebarClass;
        icon: NotificationIcon;
      }
    >
  > = {
    [NotificationTypes.SUCCESS]: {
      bgClass: NotificationClass.SUCCESS,
      sidebarClass: NotificationSidebarClass.SUCCESS,
      icon: NotificationIcon.SUCCESS
    },
    [NotificationTypes.ERROR]: {
      bgClass: NotificationClass.ERROR,
      sidebarClass: NotificationSidebarClass.ERROR,
      icon: NotificationIcon.ERROR
    },
    [NotificationTypes.WARNING]: {
      bgClass: NotificationClass.WARNING,
      sidebarClass: NotificationSidebarClass.WARNING,
      icon: NotificationIcon.WARNING
    }
  };
  constants = NotificationConstants;
  notificationType = NotificationTypes;

  constructor(
    private notificationService: NotificationService,
    private store: Store
  ) {
    this.notificationService.notifications$.subscribe((notifications) => (this.notifications = notifications));
    this.cartItems$ = this.store.select(selectCartItems);
    this.cartItems$
      .pipe(
        map((items) => {
          return items.reduce((sum, item) => sum + (item.quantity ?? 0), 0);
        }),
        map((total) => total)
      )
      .subscribe((value) => {
        this.totalSamplesCount = value;
      });
  }

  remove(id: number) {
    this.closingNotifications.add(id);
    setTimeout(() => {
      this.notificationService.removeNotification(id);
      this.closingNotifications.delete(id);
    }, 5000);
  }

  isClosing(id: number): boolean {
    return this.closingNotifications.has(id);
  }

  getNotificationBgClass(type: NotificationTypes): string {
    return this.notificationMapping[type]?.bgClass || '';
  }

  getSidebarClass(type: NotificationTypes): string {
    return this.notificationMapping[type]?.sidebarClass || '';
  }

  getIcon(type: NotificationTypes): string {
    return this.notificationMapping[type]?.icon || '';
  }

  getCartData(data?: unknown) {
    return data as ICartItem;
  }
}
